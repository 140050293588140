import React from "react"

import { graphql } from "gatsby"

import { Container, Row } from "react-bootstrap"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import CTA from "../components/CTA"
import InnerWrap from "../components/_Wrap/inner-wrap"
import WithPicture from "../components/Paragraph/WithPicture"
import { makeLink } from "../utils/google-pic-format"

const GreenBrazee = ({ data }) => {
  const { content, meta } = data
  return (
    <>
      <SEO
        title="Green Brazee"
        keywords={[`LEED`, `sustainability`, `green building`, `eco friendly`]}
      />
      <Hero src={makeLink(meta.edges[0].node.headerPicture)}></Hero>
      <Container fluid>
        <Row>
          <InnerWrap>
            <WithPicture
              title={meta.edges[0].node.sitetitle}
              subheading="Honor your recipients with original, hand-made glass."
              src={makeLink(content.edges[0].node.image)}
            >
              {content.edges.map(node => (
                <>
                  <h3>{node.node.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `<p>${node.node.content.replace(
                        /\n/g,
                        "<br/>"
                      )}</p>`,
                    }}
                  ></p>
                </>
              ))}
            </WithPicture>
          </InnerWrap>
        </Row>
        <Row>
          <CTA />
          <div style={{ height: "300px", backgroundColor: "white" }}></div>
        </Row>
      </Container>
    </>
  )
}

export const query = graphql`
  query GreenBrazeeQuery {
    meta: allGoogleSheetGreenBrazeeRow {
      edges {
        node {
          sitetitle
          headerPicture
        }
      }
    }
    content: allGoogleSheetGreenBrazeeRow {
      edges {
        node {
          title
          content
          image
        }
      }
    }
  }
`

export default GreenBrazee
